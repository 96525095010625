import fp from 'lodash/fp';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { RepositoryResources } from 'services/resources';

/**
 * Numeric value that represents the number of fetched values per `GET` request.
 */
export const DEFAULT_LIMIT = 10;

export const useFetchRepositories = () => {
  const [categories, setCategories] = useState<string[]>([]);

  const [like, setLike] = useState<null | string>(null);

  const [limit, setLimit] = useState<number>(DEFAULT_LIMIT);

  /**
   * This method add and remove elements from the `categories` state, if
   * the `entry` value doesn't exists then it will add it, otherwise it
   * will remove it.
   * @param {string} entry - category from the repository-categories arr.
   */
  const handleSetCategories = (entry: string) =>
    fp.compose(
      setCategories,
      fp.cond([
        [fp.includes(entry), fp.without([entry])],
        [fp.stubTrue, () => [...categories, entry]],
      ]),
    )(categories);

  /**
   * Fetches the repositories from the API filtering them by the `categories`,
   * `like` and `limit` values.
   */
  const query = useQuery('fetch-repositories', async () => {
    const response = await RepositoryResources.get({
      category: categories,
      limit,
      name_like: !fp.isEmpty(like) ? like : null,
      sort: 'name',
    });

    return {
      count: fp.get(['headers', 'x-total-count'])(response),
      repositories: response?.data,
    };
  });

  /**
   * Refetches the repositories from the API if and only the filters
   * `categories`, `like` or `limit` changes.
   */
  useEffect(() => {
    if (query?.isFetchedAfterMount) query?.refetch();
  }, [categories, like, limit]);

  return {
    ...query,
    categories,
    like,
    limit,
    setCategories: handleSetCategories,
    setLike,
    setLimit,
  };
};
