export const CSS = {
  footer: 'mt-6',
  header: {
    desktop: 'bg-white fixed hidden lg:flex right-0 top-0 z-10',
    mobile: 'fixed lg:hidden top-0 w-full z-10',
  },
  main: 'mt-16',
  sidebar:
    'duration-500 fixed lg:ml-0 lg:sticky top-0 transition-[margin] z-30',
};
