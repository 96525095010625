import { Chip, Input, IPropsOf, nanoid } from '@cincel.digital/design-system';
import fp from 'lodash/fp';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ICategoryResource } from 'services/resources';

import { FiltersContainer } from './Base';

export interface IFiltersProps extends IPropsOf<'div'> {
  /**
   * Repository categories available.
   */
  categories?: ICategoryResource[];
  /**
   * Action handler for onChange events in the categories filters.
   */
  onFilter: (v: string) => void;
  /**
   * Action handler for onChange events in the search input.
   */
  onSearch?: (v: string) => void;
  /**
   * Set of selected categories. The items stored in this prop will help to
   * highlight the `categories` that have been clicked by the user.
   */
  selected?: string[];
}

export const Filters: React.FC<IFiltersProps> = (props): JSX.Element => {
  const { categories = [], onFilter, onSearch, selected = [], ...rest } = props;

  const { t } = useTranslation('components:common');

  const getChipProps = useCallback(
    (category: string) => ({
      color: fp.includes(category)(selected) ? '#6344FF' : '#C4C4C4',
      textColor: fp.includes(category)(selected) ? '#6344FF' : '#000',
    }),
    [selected],
  );

  return (
    <FiltersContainer {...rest}>
      <Input
        className="mb-4 mr-0 lg:mb-0 lg:mr-3 lg:w-[200px]"
        debounceInterval={500}
        onChange={onSearch}
        placeholder={t('search')}
        primaryColor="#6344FF"
        size="sm"
      />

      <div className="flex flex-wrap gap-3 self-start">
        {categories?.map(({ name }) => (
          <Chip
            key={nanoid()}
            className="cursor-pointer"
            onClick={() => onFilter(name)}
            size="sm"
            {...getChipProps(name)}
          >
            {name}
          </Chip>
        ))}
      </div>
    </FiltersContainer>
  );
};
