import { IPropsOf } from '@cincel.digital/design-system';
import { clomp } from 'clomp';

export type ILayoutProps = IPropsOf<'section'>;

export const Layout = clomp.section`
  flex
  flex-1
  flex-col
  m-0
  p-6
  overflow-x-hidden
  overflow-y-scroll
  relative

  lg:pt-24
  lg:px-16

  2xl:px-36
`;
