import { useQuery, UseQueryResult } from 'react-query';
import { CategoryResources, ICategoryResource } from 'services/resources';

export const useFetchCategories = (): UseQueryResult<ICategoryResource[]> => {
  const query = useQuery('fetch-categories', async () => {
    const response = await CategoryResources.get();
    return response?.data;
  });

  return query;
};
