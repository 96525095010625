import { STATUS_CODE_GENERIC_MESSAGES } from 'helpers';
import fp from 'lodash/fp';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IRepositoryPayload, RepositoryResources } from 'services/resources';

export const useCreateRepository = () => {
  const { t } = useTranslation('toasts:api');

  const navigate = useNavigate();

  const fn = async (payload: IRepositoryPayload) => {
    const { cover, ...p } = payload;

    const { data: reward } = await RepositoryResources.create(p);

    await RepositoryResources.updateAsset(reward?.uuid, cover);

    toast.success(t(STATUS_CODE_GENERIC_MESSAGES[201]));

    navigate('/');
  };

  const mutation = useMutation('create-repository', fn);

  useEffect(() => {
    if (mutation.isError) {
      const STATUS_CODE = fp.get(['error', 'request', 'status'])(mutation);
      toast.dismiss();
      toast.error(t(STATUS_CODE_GENERIC_MESSAGES[STATUS_CODE]));
    }
  }, [mutation.isError]);

  return mutation;
};
