import {
  Button,
  FileField,
  InputField,
  ISelectAtomicOption,
  SelectField,
  TextareaField,
} from '@cincel.digital/design-system';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IRepositoryPayload } from 'services/resources';
import { IFormProps } from 'types.d';

import { ValidationSchema } from './helpers';

export interface IRepositoryFormProps extends IFormProps<IRepositoryPayload> {
  /**
   * Categories listing in a select's atomic option format.
   */
  categories?: ISelectAtomicOption[];
}

export const RepositoryForm: React.FC<IRepositoryFormProps> = (
  props,
): JSX.Element => {
  const { categories, defaultValues = {}, onSubmit, ...rest } = props;

  const { control, formState, handleSubmit } = useForm<IRepositoryPayload>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(ValidationSchema),
  });

  const { t } = useTranslation('components:forms');

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...rest}>
      <div className="flex flex-col justify-around md:flex-row">
        <div className="flex-1 mb-6 md:mb-0 md:mr-8">
          <InputField
            className="mb-6 w-full"
            control={control}
            isRequired
            label={t('input.repository-name.label')}
            name="name"
            primaryColor="#6344FF"
            size="sm"
          />

          <FileField
            accept=".png"
            className="mb-6 w-full"
            control={control}
            isInline
            label={t('input.logo.label')}
            labelClassName="mb-2 text-black"
            labelFontSize="md"
            name="cover"
            primaryColor="#6344FF"
          />

          <SelectField
            className="mb-6 w-full"
            control={control}
            isMulti
            isRequired
            name="categories"
            options={categories}
            placeholder={t('input.categories.label')}
            primaryColor="#6344FF"
            size="sm"
          />

          <InputField
            className="w-full"
            control={control}
            isRequired
            label={t('input.url.label')}
            name="url"
            primaryColor="#6344FF"
            size="sm"
          />
        </div>

        <div className="flex-1">
          <TextareaField
            className="h-full"
            control={control}
            isRequired
            label={t('input.repository-summary.label')}
            name="summary"
            primaryColor="#6344FF"
            size="sm"
            textareaClassName="h-full"
          />
        </div>
      </div>

      <Button
        className="float-right md:w-auto mt-6 px-8 w-full"
        isDisabled={formState?.isSubmitting || !formState?.isValid}
        isLoading={formState?.isSubmitting}
        primaryColor="#6344FF"
        size="sm"
        type="submit"
      >
        {t('button.repository.create')}
      </Button>
    </form>
  );
};
