import { H1, RewardBanner } from '@cincel.digital/design-system';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { IRepositoryResource } from 'services/resources';

export const DetailsPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation('pages:repository');

  // TODO: remove and use fetch for [GET] /repositories/{repository} ops.
  // const { data, isLoading } = useFetchRepositoryDetails();
  const { state } = useLocation() as { state: IRepositoryResource };

  return (
    <>
      <Helmet>
        <title>{t('helmet')}</title>
      </Helmet>

      <H1 className="mb-5">{t('heading')}</H1>

      <RewardBanner
        btnMsg={t('button.visualize')}
        categories={state?.categories?.map((e) => e?.name)}
        className="lg:!flex-row-reverse"
        logo={state?.cover}
        onClick={() => window.open(state?.url)}
        primaryColor="#6344FF"
        summary={state?.summary}
        title={state?.name}
      />
    </>
  );
};
