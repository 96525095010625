import fp from 'lodash/fp';
import { instance, IQueryParams, IResponse } from 'services';

import { ITimestampsResource } from './timestamps.types';

export const TimestampsResources = {
  get: (params?: IQueryParams): IResponse<ITimestampsResource[]> =>
    instance.get('/timestamps', {
      params: fp.omitBy(fp.isNil)(params),
    }),
};

export type ICountsResults = {
  [key: string]: {
    [key: string]: number;
  };
};

export const CountsResources = {
  get: (): IResponse<ICountsResults> => instance.get('/stats/counts.json', {}),
};
