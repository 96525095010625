import { nanoid, Skeleton, Table as T } from '@cincel.digital/design-system';
import _ from 'lodash';

export interface ILoadingProps {
  /**
   * Number of cols that will be rendered per row.
   */
  cols: number;
  /**
   * Number rows that will be rendered.
   */
  rows: number;
}

export const Loading: React.FC<ILoadingProps> = (props): JSX.Element => {
  const { cols, rows } = props;

  return (
    <>
      {_.times(rows).map(() => (
        <T.Tr key={nanoid()}>
          {_.times(cols).map(() => (
            <T.Td key={nanoid()}>
              <Skeleton />
            </T.Td>
          ))}
        </T.Tr>
      ))}
    </>
  );
};
