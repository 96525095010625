import { instance, IQueryParams, IResponse } from 'services';

import { IUserResource } from './users.types';

export const UserResources = {
  get: (params?: IQueryParams): IResponse<IUserResource[]> =>
    instance.get('/users', { params }),

  me: (params?: IQueryParams): IResponse<IUserResource> =>
    instance.get('/users/me', { params }),
};
