import { useEffect } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { CountsResources } from 'services/resources/timestamps/timestamps.resources';

export type ICountsStats = UseQueryResult<{
  sellos: number[];
  constancias: number[];
  meses: string[];
}>;

export const useFetchCounts = (): ICountsStats => {
  const query = useQuery(['fetch-timestamps'], async () => {
    const { data: map } = await CountsResources.get();

    /* eslint-disable no-console */
    console.log({ map });

    const meses = Object.keys(map);
    const sellos: number[] = [];
    const constancias: number[] = [];

    /* eslint-disable no-unused-expressions */
    Object.values(map).forEach((v) => {
      sellos.push(v.sellos);
      constancias.push(v.constancias);
    });

    return { constancias, meses, sellos };
  });

  /**
   * Refetches the timestamps from the API.
   */
  useEffect(() => {
    if (query?.isFetchedAfterMount) query?.refetch();
  });

  return { ...query };
};
