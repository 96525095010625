import fp from 'lodash/fp';
import { useEffect, useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { ITimestampsResource, TimestampsResources } from 'services/resources';
import { ICustomQueryParams, IPaginationParams } from 'services/services.types';

/**
 * Numeric value that represents the number of fetched values per `GET` request.
 */
export const DEFAULT_PAGE_SIZE = 10;

export type IQueryResult = UseQueryResult<{
  /**
   * Header `x-tota-count` value.
   */
  count: number;
  /**
   * Fetched resources.
   */
  timestamps: ITimestampsResource[];
}>;

export type IUseFetchTimeStampsResult = IQueryResult & {
  /**
   * Current page.
   */
  currentPage: number;
  /**
   * Action that will update the `currentPage` value.
   */
  setCurrentPage: (e: number) => void;
  /**
   * Action that will update the `pageSize` value.
   */
  setPageSize: (i: number) => void;
  /**
   * Pagination limit.
   */
  pageSize: number;
};

export const useFetchTimeStamps = (
  params: ICustomQueryParams & IPaginationParams = {},
): IUseFetchTimeStampsResult => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const pSize = params.limit || DEFAULT_PAGE_SIZE;
  const [pageSize, setPageSize] = useState<number>(pSize);

  const query = useQuery(['fetch-timestamps', params], async () => {
    const response = await TimestampsResources.get({
      ...params,
      limit: pageSize,
      offset: (currentPage - 1) * pageSize,
    });

    return {
      count: fp.get(['headers', 'x-total-count'])(response),
      timestamps: response?.data,
    };
  });

  /**
   * Refetches the timestamps from the API.
   */
  useEffect(() => {
    if (query?.isFetchedAfterMount) query?.refetch();
  }, [currentPage, pageSize]);

  return { ...query, currentPage, pageSize, setCurrentPage, setPageSize };
};
