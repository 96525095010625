import { CincelDarkIcon } from '@cincel.digital/design-system';
import { LoginForm } from 'components';
import { AuthContext } from 'contexts';
import fp from 'lodash/fp';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { Container } from './components/Container';

export const LoginPage: React.FC = (): JSX.Element | null => {
  const { authFn, token } = useContext(AuthContext);

  const { t } = useTranslation('pages:login');

  if (!fp.isNil(token)) return <Navigate to="/" />;

  return (
    <>
      <Helmet>
        <title>{t('helmet')}</title>
      </Helmet>

      <Container>
        <CincelDarkIcon className="mb-12 mx-auto" height={42} width={224} />

        <LoginForm onSubmit={authFn} />
      </Container>
    </>
  );
};
