import { IPropsOf } from '@cincel.digital/design-system';
import { clomp } from 'clomp';

export type IMainProps = IPropsOf<'main'>;

export const Main = clomp.main`
  flex
  relative

  lg:mt-0
`;
