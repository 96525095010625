import { i18n } from 'locales';
import fp from 'lodash/fp';
import { isValidFileFormat, isValidFileSize } from 'utils';
import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
  categories: Yup.array()
    .max(3, i18n.t('error.array.max-3', { ns: 'components:forms' }))
    .min(1, i18n.t('error.array.min-1', { ns: 'components:forms' }))
    .of(Yup.string()),

  cover: Yup.mixed()
    .test(
      'file-size',
      i18n.t('error.file.size-512kb', { ns: 'components:forms' }),
      (v) => {
        if (fp.isNil(v)) return true;
        if (fp.isString(v)) return true;
        return isValidFileSize(v.size);
      },
    )
    .test(
      'file-format',
      i18n.t('error.file.format-png', { ns: 'components:forms' }),
      (v) => {
        if (fp.isNil(v)) return true;
        if (fp.isString(v)) return true;
        return isValidFileFormat(v.type);
      },
    )
    .required(i18n.t('error.required', { ns: 'components:forms' })),

  name: Yup.string().required(
    i18n.t('error.required', { ns: 'components:forms' }),
  ),

  summary: Yup.string()
    .max(140, i18n.t('error.chars.max-140', { ns: 'components:forms' }))
    .required(i18n.t('error.required', { ns: 'components:forms' })),

  url: Yup.string()
    .url(i18n.t('error.url', { ns: 'components:forms' }))
    .required(i18n.t('error.required', { ns: 'components:forms' })),
});
