export const STATUS_CODE_GENERIC_MESSAGES = {
  201: 'default.status-code.201',
  400: 'default.status-code.400',
  401: 'default.status-code.401',
  403: 'default.status-code.403',
  404: 'default.status-code.404',
  413: 'default.status-code.413',
  415: 'default.status-code.415',
  500: 'default.status-code.500',
};
