import { Divider, H1 } from '@cincel.digital/design-system';
import { useFetchCounts } from 'hooks/useFetchCounts';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Metrics } from './components/Metrics';

export const AnalyticsPage: React.FC = (): JSX.Element => {
  const { data } = useFetchCounts();
  const { meses = [], sellos = [], constancias = [] } = data || {};
  /* eslint-disable no-console */
  console.log({ data });

  const { t } = useTranslation('pages:analytics');

  return (
    <>
      <Helmet>
        <title>{t('helmet')}</title>
      </Helmet>

      <H1 className="mb-5">{t('heading')}</H1>

      <Divider className="bg-gray-200 !h-[2px] mb-5 w-full" />

      <Metrics
        className="mb-8"
        data={sellos}
        labels={meses}
        title={t('stats.timestamps')}
        value={sellos.reduce((p, a) => p + a, 0).toString()}
      />

      <Metrics
        className="mb-8"
        data={constancias}
        labels={meses}
        title={t('stats.constancies')}
        value={constancias.reduce((p, a) => p + a, 0).toString()}
      />
    </>
  );
};
