import { Dispatch, useCallback, useEffect } from 'react';
import { UserResources } from 'services/resources';

import { Action } from '../helpers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useRestoreSession = (dispatch: Dispatch<any>): void => {
  const bootstrapAsync = useCallback(async () => {
    try {
      dispatch({ type: Action.RESTORE_SESSION });

      const token = localStorage.getItem('token');

      const { data: user } = await UserResources.me();

      dispatch({ token, type: Action.RESTORE_SESSION_SUCCESS, user });
    } catch (e) {
      dispatch({ type: Action.RESTORE_SESSION_FAILURE });
    }
  }, []);

  useEffect(() => {
    bootstrapAsync();
  }, [bootstrapAsync]);
};
