import {
  Button,
  Empty,
  H1,
  nanoid,
  RewardCard,
} from '@cincel.digital/design-system';
import { cx } from '@emotion/css';
import fp from 'lodash/fp';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ListingGrid } from './components/Base';
import { Filters } from './components/Filters';
import {
  DEFAULT_LIMIT,
  useFetchCategories,
  useFetchRepositories,
} from './hooks';

export const ListingPage: React.FC = (): JSX.Element => {
  const {
    categories: selected,
    data,
    isLoading,
    isRefetching,
    limit,
    setLike,
    setLimit,
    setCategories,
  } = useFetchRepositories();

  const { data: categories } = useFetchCategories();

  const { t } = useTranslation('pages:repository');

  const location = useLocation();

  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>{t('helmet')}</title>
      </Helmet>

      <H1 className="mb-5">{t('heading')}</H1>

      <Filters
        categories={categories}
        className="lg:mb-20 mb-12"
        onFilter={setCategories}
        onSearch={setLike}
        selected={selected}
      />

      <ListingGrid className={cx({ hidden: isLoading })}>
        {data?.repositories?.map((item) => (
          <RewardCard
            key={nanoid()}
            categories={item?.categories?.map((e) => e?.name)}
            cover={item?.cover}
            onClick={() =>
              navigate(`${location.pathname}/${item?.uuid}`, { state: item })
            }
            primaryColor="#6344FF"
            summary={item?.summary}
            title={item?.name}
          />
        ))}
      </ListingGrid>

      <ListingGrid className={cx({ hidden: !isLoading })}>
        {[...Array(DEFAULT_LIMIT)]?.map(() => (
          <RewardCard key={nanoid()} isLoading primaryColor="#6344FF" />
        ))}
      </ListingGrid>

      {fp.isEmpty(data?.repositories) && !isLoading ? (
        <Empty>{t('empty-data', { ns: 'components:common' })}</Empty>
      ) : null}

      {data?.count > limit ? (
        <Button
          className="lg:mt-20 md:w-48 mt-12 mx-auto text-white w-full"
          isDisabled={isRefetching}
          isLoading={isRefetching}
          onClick={() => setLimit(limit + DEFAULT_LIMIT)}
          primaryColor="#6344FF"
          size="xs"
          variant="solid"
        >
          {t('button.load-more')}
        </Button>
      ) : null}
    </>
  );
};
