import { IPropsOf } from '@cincel.digital/design-system';
import { clomp } from 'clomp';

export type IBackgroundProps = IPropsOf<'div'>;

export const Background: React.FC<IBackgroundProps> = clomp.div`
  bg-black
  block
  duration-500
  fixed
  inset-0
  opacity-50
  transition-all
  z-20

  lg:hidden
`;
