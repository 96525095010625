import { Divider, H1 } from '@cincel.digital/design-system';
import { RepositoryForm } from 'components/forms';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { useCreateRepository, useFetchCategories } from './hooks';

export const CreatePage: React.FC = (): JSX.Element => {
  const { mutateAsync } = useCreateRepository();

  const { data } = useFetchCategories();

  const { t } = useTranslation('pages:admin');

  return (
    <>
      <Helmet>
        <title>{t('helmet')}</title>
      </Helmet>

      <H1 className="mb-5">{t('heading.create')}</H1>

      <Divider className="bg-gray-200 !h-[2px] mb-5 w-full" />

      <RepositoryForm categories={data} onSubmit={mutateAsync} />
    </>
  );
};
