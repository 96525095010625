import fp from 'lodash/fp';
import moment from 'moment';

const GREEN = '#4da74d';

const RED = '#db6464';

export const StatusEnum = {
  1: 'status.down',
  2: 'status.up',
  98: 'status.started',
};

export const TABLE_HEADERS = [
  'table.header.id',
  'table.header.event',
  'table.header.datetime',
  'table.header.details',
];

export const formatDate = (d: number, f = 'LT') => moment.unix(d).format(f);

export const getResponseTimesDates = fp.compose(
  fp.reverse,
  fp.map((d: number) => formatDate(d)),
  fp.map(({ datetime }: { datetime: number }) => datetime),
  fp.get('responsetimes'),
);

export const getResponseTimesValues = fp.compose(
  fp.reverse,
  fp.map(({ value }: { value: number }) => value),
  fp.get('responsetimes'),
);

export const getStatusColor = fp.compose(
  fp.curry((v: number) => (v === 2 ? GREEN : RED)),
  fp.get('status'),
);

export const getStatusAndFormat = fp.compose(
  fp.curry((v: number) => (v === 2 ? 100 : 0)),
  fp.get('status'),
);

export const getAverageResponseTime = fp.get('average_response_time');
