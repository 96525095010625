/**
 * Validates if the `size` param is lower than the max size established.
 * @param {number} size - file atomic size.
 * @param {number} MAX_FILE_SIZE - max size to validate with. Default is 512KB.
 * @returns {boolean}
 */
export const isValidFileSize = (
  size: number,
  MAX_FILE_SIZE = 516000,
): boolean => size <= MAX_FILE_SIZE;

/**
 * Validates if the multipart data is in the valid formats.
 * @param {string} value - multipart data.
 * @param {string[]} FORMATS - valid formats.
 * @returns {boolean}
 */
export const isValidFileFormat = (
  value: string,
  FORMATS = ['image/png'],
): boolean => FORMATS?.includes(value);
