import {
  Header,
  Sidebar,
  Stack,
  UserMenu,
  useSidebar,
  useToggle,
} from '@cincel.digital/design-system';
import { cx } from '@emotion/css';
import Logo from 'assets/img/logo.light.png';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import { Background, Footer, Layout, Main } from 'components';
import { AuthContext } from 'contexts';
import {
  getExact100vhCSS,
  getMin100vhCSS,
  SIDEBAR_NAVIGATION,
  USER_SIDEBAR_NAVIGATION,
} from 'helpers';
import fp from 'lodash/fp';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { use100vh } from 'react-div-100vh';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import { CSS } from '../helpers';

export const Protected: React.FC = (): JSX.Element | null => {
  const { isLoading, logoutFn, token, user } = useContext(AuthContext);

  const contentRef = useRef<HTMLDivElement>(null);

  const sidebarRef = useRef<HTMLBaseElement>(null);

  const [isOpen, toggle] = useToggle(false);

  const height = use100vh();

  const navigate = useNavigate();

  const sidebarCSS = useMemo(
    () =>
      cx(
        CSS.sidebar,
        getExact100vhCSS(height),
        isOpen ? 'ml-0' : 'ml-[-320px]',
      ),
    [height, isOpen],
  );

  useSidebar(sidebarRef, isOpen, toggle);

  useEffect(() => {
    if (contentRef?.current && isOpen) {
      disableBodyScroll(contentRef?.current);
    }
    if (contentRef?.current && !isOpen) {
      enableBodyScroll(contentRef?.current);
    }
    return () => clearAllBodyScrollLocks();
  }, [contentRef, isOpen]);

  if (isLoading) return null;

  if (fp.isNil(token)) return <Navigate replace to="/login" />;

  return (
    <Main className={cx(getMin100vhCSS(height), CSS.main)}>
      <Header
        className={CSS.header.mobile}
        logo={Logo}
        onClick={() => toggle()}
      />

      <UserMenu
        className={CSS.header.desktop}
        isAuthenticate
        navigation={[{ ...USER_SIDEBAR_NAVIGATION, to: logoutFn }]}
        primaryColor="transparent"
        username={user?.name as string}
      />

      <Sidebar
        ref={sidebarRef}
        className={sidebarCSS}
        isAuthenticate
        logo={Logo}
        navigation={SIDEBAR_NAVIGATION}
        onClick={() => navigate('/')}
        primaryColor="#6344FF"
        user={user}
        userNav={[{ ...USER_SIDEBAR_NAVIGATION, to: logoutFn }]}
      />

      <Background className={cx(!isOpen ? 'hidden' : 'block')} />

      <Layout ref={contentRef}>
        <Stack className="flex-1">
          <Outlet />
        </Stack>
        <Footer className={CSS.footer} />
      </Layout>
    </Main>
  );
};
