import { css } from '@emotion/css';
import fp from 'lodash/fp';

export const getMin100vhCSS = (h: null | number): string => css`
  min-height: calc(${!fp.isNil(h) ? h : 0}px - 4rem);
`;

export const getExact100vhCSS = (h: null | number): string => css`
  height: ${!fp.isNil(h) ? h : 0}px;
`;
