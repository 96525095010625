import { createContext } from 'react';
import { IBaseAuthentication, IUserResource } from 'services/resources';

export interface IAuthContextProps {
  /**
   * Async function that will request to the API for the authentication token.
   * @param {IBaseAuthentication} auth - basic auth payload.
   */
  authFn: (auth: IBaseAuthentication) => Promise<void>;
  /**
   * If `true`, the context will change to an error state.
   */
  isError: boolean;
  /**
   * If `true`, the context will change to a loading state.
   */
  isLoading: boolean;
  /**
   * Async function that will close the user session and will remove the token
   * from the secure storage.
   */
  logoutFn: () => Promise<void>;
  /**
   * Authenticated user's access token.
   */
  token: null | string;
  /**
   * Authenticated user information.
   */
  user: IUserResource | null;
}

export const AuthContext = createContext({} as IAuthContextProps);
