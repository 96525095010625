import fp from 'lodash/fp';
import { useQuery } from 'react-query';
import { MiscResources } from 'services/resources';

import { QUERY_PARAMS, UPTIMEROBOT_PAIRS } from '../helpers';

export const useMonitors = () => {
  const query = useQuery('fetch-monitors', async () => {
    const response = await MiscResources.getMonitors(QUERY_PARAMS);

    return fp.compose(
      fp.pick(UPTIMEROBOT_PAIRS),
      fp.head,
      fp.get(['data', 'monitors']),
    )(response);
  });

  return query;
};
