import { nanoid } from '@cincel.digital/design-system';
import { AuthContext } from 'contexts';
import { LoginPage } from 'pages/LoginPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { setupInterceptors } from 'services';

import { Protected } from './components/Protected';
import { ROUTES } from './helpers/router-helpers';

export const App: React.FC = (): JSX.Element => {
  const { logoutFn } = useContext(AuthContext);

  setupInterceptors(logoutFn);

  return (
    <Routes>
      <Route element={<Protected />}>
        {ROUTES?.map(({ element: Element, path }) => (
          <Route key={nanoid()} element={<Element />} path={path} />
        ))}
      </Route>

      <Route element={<LoginPage />} path="/login" />
      <Route element={<NotFoundPage />} path="*" />
    </Routes>
  );
};
