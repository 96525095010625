import { clomp } from 'clomp';

export const Container = clomp.main`
  bg-white
  m-8

  md:py-12

  xl:py-16
`;
