import common from './components/common.json';
import forms from './components/forms.json';
import admin from './pages/admin.json';
import analytics from './pages/analytics.json';
import ccmds from './pages/ccmds.json';
import login from './pages/login.json';
import repository from './pages/repository.json';
import status from './pages/status.json';
import timestamps from './pages/timestamps.json';
import api from './toasts/api.json';
import toastErrors from './toasts/errors.json';

export const translationsES = {
  'components:common': common,
  'components:forms': forms,
  'pages:admin': admin,
  'pages:analytics': analytics,
  'pages:ccmds': ccmds,
  'pages:login': login,
  'pages:repository': repository,
  'pages:status': status,
  'pages:timestamps': timestamps,
  'toasts:api': api,
  'toasts:errors': toastErrors,
};
