import { formatDate } from 'helpers';
import { i18n } from 'locales';
import fp from 'lodash/fp';

export const TABLE_HEADERS = [
  i18n.t('table.header.hash', { ns: 'pages:timestamps' }),
  i18n.t('table.header.issuer', { ns: 'pages:timestamps' }),
  i18n.t('table.header.date', { ns: 'pages:timestamps' }),
];

export const truncateHash = fp.compose(
  fp.truncate({ length: 24 }),
  fp.get('hash'),
);

export const getDateAndFormat = fp.compose(formatDate, fp.get('created_at'));
