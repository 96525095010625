import { Divider, H1, nanoid, Table as T } from '@cincel.digital/design-system';
import { Table } from 'components';
import { useFetchTimeStamps } from 'hooks';
import fp from 'lodash/fp';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ITimestampsResource } from 'services/resources';

import { getDateAndFormat, TABLE_HEADERS, truncateHash } from './helpers';

export const TimeStampsPage: React.FC = (): JSX.Element | null => {
  const {
    currentPage,
    data,
    isLoading,
    pageSize,
    setCurrentPage,
    setPageSize,
  } = useFetchTimeStamps();

  const { t } = useTranslation('pages:timestamps');

  return (
    <>
      <Helmet>
        <title>{t('helmet')}</title>
      </Helmet>

      <H1 className="mb-5">{t('heading')}</H1>

      <Divider className="bg-gray-200 !h-[2px] mb-5 w-full" />

      <Table
        currentPage={currentPage}
        fallbackRows={pageSize}
        headers={TABLE_HEADERS}
        isLoading={isLoading}
        onPageChange={setCurrentPage}
        onPageSizeChange={setPageSize}
        pageSize={pageSize}
        primaryColor="#6344FF"
        siblings={1}
        total={data?.count || 0}
      >
        {fp.map((item: ITimestampsResource) => (
          <T.Tr key={nanoid()}>
            <T.Td>{truncateHash(item)}</T.Td>
            <T.Td>{item?.issuer_common_name}</T.Td>
            <T.Td>{getDateAndFormat(item)}</T.Td>
          </T.Tr>
        ))(data?.timestamps)}
      </Table>
    </>
  );
};
