import { AuthContext } from 'contexts';
import React, { useMemo } from 'react';

import { useAuth, useAuthReducer, useLogout, useRestoreSession } from './hooks';

export const AuthProvider: React.FC<React.PropsWithChildren> = (
  props,
): JSX.Element => {
  const { children } = props;

  const [state, dispatch] = useAuthReducer();

  const [authFn] = useAuth(dispatch);

  const [logoutFn] = useLogout(dispatch);

  const context = useMemo(() => ({ authFn, logoutFn, ...state }), [state]);

  useRestoreSession(dispatch);

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
