export const QUERY_PARAMS = {
  api_key: process.env.REACT_APP_UPTIMEROBOT_API_KEY,
  logs: 1,
  logs_limit: 15,
  monitors: process.env.REACT_APP_UPTIMEROBOT_MONITOR_ID,
  response_times: 1,
  response_times_average: 15,
  ssl: 1,
};

export const UPTIMEROBOT_PAIRS = [
  'status',
  'ssl',
  'logs',
  'responsetimes',
  'average_response_time',
];
