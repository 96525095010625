import { AdminCreatePage } from 'pages/AdminPages';
import { AnalyticsPage } from 'pages/AnalyticsPage';
import { CertificatesPage } from 'pages/CertificatesPage';
import {
  RepositoryDetailsPage,
  RepositoryListingPage,
} from 'pages/RepositoryPages';
import { StatusPage } from 'pages/StatusPage';
import { TimeStampsPage } from 'pages/TimeStampsPage';

export const ROUTES = [
  {
    element: AnalyticsPage,
    path: '',
  },
  {
    element: RepositoryListingPage,
    path: '/repository',
  },
  {
    element: RepositoryDetailsPage,
    path: '/repository/:uuid',
  },
  {
    element: TimeStampsPage,
    path: 'timestamps',
  },
  {
    element: CertificatesPage,
    path: '/ccmds',
  },
  {
    element: AdminCreatePage,
    path: 'admin/create',
  },
  {
    element: StatusPage,
    path: 'status',
  },
];
