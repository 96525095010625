import fp from 'lodash/fp';
import qs from 'query-string';
import { instance, IQueryParams, IResponse } from 'services';

import { IRepositoryPayload, IRepositoryResource } from './repository.types';

const IMG_HEADER = {
  headers: {
    'content-type': 'image/png',
  },
};

export const RepositoryResources = {
  create: (params: IRepositoryPayload): IResponse<IRepositoryResource> =>
    instance.post('/repositories', params),

  get: (params?: IQueryParams): IResponse<IRepositoryResource[]> =>
    instance.get('/repositories', {
      params: fp.omitBy(fp.isNil)(params),
      paramsSerializer: (p) => qs.stringify(p),
    }),

  getOne: (uuid: string): IResponse<IRepositoryResource> =>
    instance.get(`/repositories/${uuid}`),

  updateAsset: (uuid: string, params?: File): IResponse<IRepositoryResource> =>
    instance.patch(
      `/repositories/${uuid}/assets/cover.png`,
      params,
      IMG_HEADER,
    ),
};
