import { i18n } from 'locales';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { instance } from './services';

const TOKEN_PATH = ['headers', 'Authorization'];

export const setupInterceptors = (logoutFn: () => void): void => {
  instance.interceptors.request.use(
    (config) => {
      const TOKEN = localStorage.getItem('token');

      if (TOKEN) _.set(config, TOKEN_PATH, `Bearer ${TOKEN}`);

      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  instance.interceptors.response.use(_.identity, ({ response }) => {
    const isTokenExpired = ({ status }) => status === 401;

    const TOKEN = localStorage.getItem('token');

    if (isTokenExpired(response) && !_.isNil(TOKEN)) {
      try {
        // TODO: handle refresh token.
        throw Error;
      } catch {
        _.unset(instance, TOKEN_PATH);
        const MESSAGE = i18n.t('default.status-code.401', { ns: 'toasts:api' });
        toast.error(MESSAGE);
        logoutFn();
      }
    }

    return Promise.reject(response);
  });
};
