import {
  AreaChart,
  IPropsOf,
  Stat,
  StatLabel,
  StatValue,
} from '@cincel.digital/design-system';
import { cx } from '@emotion/css';

export interface IMetricsProps extends IPropsOf<'div'> {
  /**
   * Data that is passed into the chart y-axis.
   */
  data: number[];
  /**
   * Labels that will be displayed in the x-axis of the chart.
   */
  labels: string[];
  /**
   * Stat card title.
   */
  title: string;
  /**
   * Stat card value.
   */
  value: string;
}

export const Metrics: React.FC<IMetricsProps> = (props): JSX.Element => {
  const { className, data, labels, title, value, ...rest } = props;

  return (
    <div className={cx('flex flex-col gap-4 md:flex-row', className)} {...rest}>
      <Stat className="flex items-center md:w-1/4 w-full">
        <StatLabel className="text-center">{title}</StatLabel>
        <StatValue>{value}</StatValue>
      </Stat>

      <AreaChart
        className="md:w-3/4 w-full"
        color="#6344FF"
        data={data}
        labels={labels}
        name={title}
      />
    </div>
  );
};
