import {
  Empty,
  IPaginationProps,
  Pagination,
  Table as T,
} from '@cincel.digital/design-system';
import fp from 'lodash/fp';
import { Children } from 'react';
import { useTranslation } from 'react-i18next';

import { Loading } from './components/Loading';

export interface ITableProps extends IPaginationProps {
  /**
   * Number of rows that will be rendered in a loading state.
   */
  fallbackRows?: number;
  /**
   * Table headers
   */
  headers: string[];
  /**
   * If `true`, the table will render skeleton data.
   */
  isLoading?: boolean;
}

export const Table: React.FC<ITableProps> = (props): JSX.Element => {
  const {
    children,
    currentPage,
    fallbackRows = 0,
    headers,
    isLoading = false,
    onPageChange,
    onPageSizeChange,
    pageSize,
    primaryColor,
    siblings,
    total,
  } = props;

  const { t } = useTranslation('components:common');

  if ((fp.isEmpty(children) || fp.isNil(children)) && !isLoading) {
    return <Empty>{t('empty-data')}</Empty>;
  }

  return (
    <>
      <T.Container>
        <T>
          <T.Thead>
            <T.Tr>
              {headers?.map((header) => (
                <T.Th key={header} scope="col">
                  {t(header)}
                </T.Th>
              ))}
            </T.Tr>
          </T.Thead>

          <T.Tbody>
            {!isLoading ? (
              Children.toArray(children)
            ) : (
              <Loading cols={headers?.length} rows={fallbackRows} />
            )}
          </T.Tbody>
        </T>
      </T.Container>

      <Pagination
        className="md:px-8 mt-4 px-0"
        currentPage={currentPage}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pageSize={pageSize}
        primaryColor={primaryColor}
        siblings={siblings}
        total={total}
      />
    </>
  );
};
