import {
  IPropsOf,
  nanoid,
  Skeleton,
  Table as T,
} from '@cincel.digital/design-system';
import _ from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate, QUERY_PARAMS, StatusEnum } from '../helpers';

export interface ITableProps extends IPropsOf<'div'> {
  /**
   * Table data to render.
   */
  // TODO: define the monitors interface.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  /**
   * Table headers.
   */
  headers: string[];
  /**
   * If `true`, the table will render skeleton data.
   */
  isLoading?: boolean;
}

export const Table: React.FC<ITableProps> = (props): JSX.Element => {
  const { data, headers, isLoading = false, ...rest } = props;

  const { t } = useTranslation('pages:status');

  const renderer = useCallback(
    () =>
      data?.map((item) => (
        <T.Tr key={nanoid()}>
          <T.Td>{item?.id}</T.Td>
          <T.Td>{t(StatusEnum[item?.type])}</T.Td>
          <T.Td>{formatDate(item?.datetime, 'L')}</T.Td>
          <T.Td>{`${item?.reason?.code} ${item?.reason?.detail}`}</T.Td>
        </T.Tr>
      )),
    [data],
  );

  const skeletonRenderer = useCallback(
    () =>
      _.times(QUERY_PARAMS?.logs_limit).map(() => (
        <T.Tr key={nanoid()}>
          {_.times(headers.length).map(() => (
            <T.Td key={nanoid()}>
              <Skeleton />
            </T.Td>
          ))}
        </T.Tr>
      )),
    [headers, QUERY_PARAMS?.logs_limit],
  );

  return (
    <T.Container {...rest}>
      <T>
        <T.Thead>
          <T.Tr>
            {headers?.map((header) => (
              <T.Th key={header} scope="col">
                {t(header)}
              </T.Th>
            ))}
          </T.Tr>
        </T.Thead>

        <T.Tbody>{!isLoading ? renderer() : skeletonRenderer()}</T.Tbody>
      </T>
    </T.Container>
  );
};
