import { Button, InputField } from '@cincel.digital/design-system';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IBaseAuthentication } from 'services/resources';
import { IFormProps } from 'types.d';

import { Form } from './components/Form';
import { DEFAULT_VALUES, LoginFormValidationSchema } from './helpers';

export type ILoginFormProps = IFormProps<IBaseAuthentication>;

export const LoginForm: React.FC<ILoginFormProps> = (props): JSX.Element => {
  const {
    className,
    defaultValues = DEFAULT_VALUES,
    onSubmit,
    ...rest
  } = props;

  const { control, formState, handleSubmit } = useForm<IBaseAuthentication>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(LoginFormValidationSchema),
  });

  const { t } = useTranslation('components:forms');

  return (
    <Form className={className} onSubmit={handleSubmit(onSubmit)} {...rest}>
      <InputField
        className="mb-4 md:w-[300px] w-full"
        control={control}
        name="username"
        placeholder={t('input.email.label')}
        primaryColor="#6344FF"
        size="md"
        type="email"
      />

      <InputField
        className="mb-6 md:w-[300px] w-full"
        control={control}
        name="password"
        placeholder={t('input.password.label')}
        primaryColor="#6344FF"
        size="md"
        type="password"
      />

      <Button
        className="md:w-[300px] w-full"
        isDisabled={formState?.isSubmitting || !formState?.isValid}
        isLoading={formState?.isSubmitting}
        primaryColor="#6344FF"
        size="sm"
        type="submit"
      >
        {t('button.login')}
      </Button>
    </Form>
  );
};
