import {
  AnalyticsIcon,
  FingerprintIcon,
  HelpIcon,
  INavigation,
  RepositoryIcon,
  SettingsIcon,
  StatusIcon,
  VerificationIcon,
} from '@cincel.digital/design-system';
import { i18n } from 'locales';

const ICON_PROPS = { color: '#FFF', height: 25, width: 25 };

export const SIDEBAR_NAVIGATION: INavigation[] = [
  {
    icon: <AnalyticsIcon {...ICON_PROPS} />,
    isExternal: false,
    label: i18n.t('navigation.analytics', { ns: 'components:common' }),
    to: '/',
  },
  {
    icon: <RepositoryIcon {...ICON_PROPS} />,
    isExternal: false,
    label: i18n.t('navigation.repository', { ns: 'components:common' }),
    to: '/repository',
  },
  {
    icon: <FingerprintIcon {...ICON_PROPS} />,
    isExternal: false,
    label: i18n.t('navigation.timestamps', { ns: 'components:common' }),
    to: '/timestamps',
  },
  {
    icon: <VerificationIcon {...ICON_PROPS} />,
    isExternal: false,
    label: i18n.t('navigation.ccmds', { ns: 'components:common' }),
    to: '/ccmds',
  },
  {
    icon: <StatusIcon {...ICON_PROPS} />,
    isExternal: false,
    label: i18n.t('navigation.status', { ns: 'components:common' }),
    to: '/status',
  },
  {
    icon: <SettingsIcon {...ICON_PROPS} />,
    isExternal: false,
    label: i18n.t('navigation.admin', { ns: 'components:common' }),
    routes: [
      {
        isExternal: false,
        label: i18n.t('navigation.admin.create', { ns: 'components:common' }),
        to: '/admin/create',
      },
    ],
  },
  {
    icon: <HelpIcon {...ICON_PROPS} />,
    isExternal: true,
    label: i18n.t('navigation.help', { ns: 'components:common' }),
    routes: [
      {
        isExternal: true,
        label: i18n.t('navigation.help.faqs', { ns: 'components:common' }),
        to: 'https://help.cincel.digital/preguntas-frecuentes#psc',
      },
      {
        isExternal: true,
        label: i18n.t('navigation.help.service-request', {
          ns: 'components:common',
        }),
        to: 'https://www.cincel.digital/psc/#cotizar',
      },
      {
        isExternal: true,
        label: i18n.t('navigation.help.tech-support', {
          ns: 'components:common',
        }),
        to: 'https://help.cincel.digital/kb-tickets/new',
      },
    ],
    to: 'https://help.cincel.digital/',
  },
];

export const USER_SIDEBAR_NAVIGATION = {
  label: i18n.t('logout', { ns: 'components:common' }),
};
