import { i18n } from 'locales';
import * as Yup from 'yup';

export const LoginFormValidationSchema = Yup.object().shape({
  password: Yup.string().required(
    i18n.t('error.required', { ns: 'components:forms' }),
  ),
  username: Yup.string()
    .email(i18n.t('error.email', { ns: 'components:forms' }))
    .required(i18n.t('error.required', { ns: 'components:forms' })),
});

export const DEFAULT_VALUES = { password: '', username: '' };
