import { ISelectAtomicOption } from '@cincel.digital/design-system';
import fp from 'lodash/fp';
import { useQuery, UseQueryResult } from 'react-query';
import { CategoryResources, ICategoryResource } from 'services/resources';

export const useFetchCategories = (): UseQueryResult<ISelectAtomicOption[]> => {
  const query = useQuery('fetch-repository-categories', async () => {
    const response = await CategoryResources.get();

    return fp.compose(
      fp?.map((v: ICategoryResource) => ({ label: v?.name, value: v?.uuid })),
      fp.get('data'),
    )(response);
  });

  return query;
};
