import { Dispatch, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  AuthResources,
  IBaseAuthentication,
  UserResources,
} from 'services/resources';

import { Action } from '../helpers';

/**
 * Async function that will request to the API the authentication token.
 * @param {T} p - basic auth payload.
 */
export type IUseAuthFn<T> = (auth: T) => Promise<void>;

export const useAuth = <T extends IBaseAuthentication>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
): [IUseAuthFn<T>] => {
  const { t } = useTranslation('toasts:errors');

  const navigate = useNavigate();

  const fn = useCallback(async (p: T) => {
    try {
      dispatch({ type: Action.AUTH });

      const { data: token } = await AuthResources.auth(p);

      localStorage.setItem('token', token);

      const { data: user } = await UserResources.me();

      dispatch({ token, type: Action.AUTH_SUCCESS, user });

      navigate('/');
    } catch (err) {
      dispatch({ type: Action.AUTH_FAILURE });
      toast.dismiss();
      toast.error(t('auth'));
    }
  }, []);

  return [fn];
};
