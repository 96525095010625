import { Dispatch, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Action } from '../helpers';

/**
 * Async function that will close the user session and will remove the tokens
 * from the secure storage.
 */
export type IUseLogoutFn = () => Promise<void>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useLogout = (dispatch: Dispatch<any>): [IUseLogoutFn] => {
  const navigate = useNavigate();

  const fn = useCallback(async () => {
    localStorage.removeItem('token');

    navigate('/login');

    dispatch({ type: Action.LOGOUT });
  }, []);

  return [fn];
};
