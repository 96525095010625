import {
  AreaChart,
  Divider,
  H1,
  H2,
  Progress,
  Skeleton,
  StatusIcon,
} from '@cincel.digital/design-system';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Table } from './components/Table';
import {
  getAverageResponseTime,
  getResponseTimesDates,
  getResponseTimesValues,
  getStatusAndFormat,
  getStatusColor,
  TABLE_HEADERS,
} from './helpers';
import { useMonitors } from './hooks';

export const StatusPage: React.FC = (): JSX.Element | null => {
  const { data, isLoading } = useMonitors();

  const { t } = useTranslation('pages:status');

  return (
    <>
      <Helmet>
        <title>{t('helmet')}</title>
      </Helmet>

      <H1 className="mb-5">{t('heading')}</H1>

      <Divider className="bg-gray-200 !h-[2px] mb-5 w-full" />

      <H2 className="flex gap-2 items-center mb-2">
        <StatusIcon color={getStatusColor(data)} />
        <span>
          {t('heading.uptime', { status: isLoading ? '0%' : '100%' })}
        </span>
      </H2>

      {!isLoading ? (
        <Progress
          className="mb-5"
          primaryColor="#6344FF"
          size="lg"
          value={getStatusAndFormat(data)}
        />
      ) : (
        <Skeleton
          className="mb-5 rounded-full"
          containerClassName="-mt-1"
          height={10}
        />
      )}

      <H2 className="mb-2">
        {t('heading.response-time', {
          average: !isLoading ? getAverageResponseTime(data) : '0.0',
        })}
      </H2>

      {!isLoading ? (
        <AreaChart
          className="h-64 mb-5"
          color="#6344FF"
          data={getResponseTimesValues(data)}
          labels={getResponseTimesDates(data)}
          name={t('status.milliseconds')}
        />
      ) : (
        <Skeleton className="mb-7" height={240} />
      )}

      <H2 className="mb-2">{t('heading.logs')}</H2>

      <Table data={data?.logs} headers={TABLE_HEADERS} isLoading={isLoading} />
    </>
  );
};
