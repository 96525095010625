import { IPropsOf } from '@cincel.digital/design-system';
import { Trans } from 'react-i18next';

import { Base } from './components/Base';

export type IFooterProps = IPropsOf<'footer'>;

export const Footer: React.FC<IFooterProps> = (props): JSX.Element => (
  <Base {...props}>
    <Trans i18nKey="footer" ns="components:common">
      <span className="text-md text-[#A5A5A5]" />
      <span className="font-bold text-md text-[#A5A5A5]" />
    </Trans>
  </Base>
);
