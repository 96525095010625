import { clomp } from 'clomp';

export const FiltersContainer = clomp.div`
  flex
  flex-col

  lg:flex-row
`;

export const ListingGrid = clomp.div`
  gap-12
  grid
  grid-cols-1
  place-items-center

  lg:grid-cols-3
  md:grid-cols-2
`;
